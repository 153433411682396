import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';

import { toSlug, toTitle } from '../../../../utils/formatters';

function DesktopNavLink( { link, opacity, contact, sticky } ) {
	const [ active, setActive ] = useState( false );
	const dropdownRef = useRef( null );

	useEffect( () => {
		const listener = event => {
			const node = dropdownRef.current;

			if ( !node || node.contains( event.target ) ) {
				return;
			}

			setActive( false );
		};

		const mouseMoveListener = event => {
			let threshold = 0;

			if ( dropdownRef.current ) {
				threshold = dropdownRef.current.getBoundingClientRect().bottom;
			}

			if ( event.clientY > threshold ) {
				setActive( false );
			}
		};

		document.addEventListener( 'mousedown', listener );
		document.addEventListener( 'mousemove', mouseMoveListener );
		document.addEventListener( 'touchstart', listener );

		return () => {
			document.removeEventListener( 'mousedown', listener );
			document.removeEventListener( 'mousemove', mouseMoveListener );
			document.removeEventListener( 'touchstart', listener );
		};
	}, [ dropdownRef ] );

	return (
		<div
			className="desktopNavLink"
			onMouseEnter={() => setActive( true )}
			id="navLink"
			aria-expanded={active}
		>
			<Link to={ toSlug( link.page ) }>
				{ toTitle( link.page ) } {link.associatedPages && <FontAwesomeIcon style={{ marginLeft: '4px', width: '.75rem' }} icon={ faCaretDown } />}
			</Link>
			{link.associatedPages && (
				<motion.ul
					ref={dropdownRef}
					onMouseLeave={() => setActive( false )}
					id="dropdown"
					area-label="menu"
					className={active ? 'desktopNavDropdownVisible' : 'desktopNavDropdownHidden'}
					initial={ sticky }
					animate={ sticky ? 'sticky' : 'default' }
					variants={{
						default: {
							backgroundColor:
							opacity
								? contact
									? 'rgba(4, 26, 47, 0.1)'
									: 'rgba(4, 26, 47, 0.75)'
								: '#041a2f'
						},
						sticky: { backgroundColor: '#041a2f' }
					}}
					transition={{ duration: 0.3, ease: 'easeInOut' }}				>
					{link.associatedPages.map( ( page, i ) => (
						<li key={i}>
							<Link to={ toSlug( page ) }>{toTitle( page )}</Link>
						</li>
					) )}
				</motion.ul> )
			}
		</div>
	);
}

export default DesktopNavLink;

DesktopNavLink.propTypes = {
	link: PropTypes.object.isRequired,
	opacity: PropTypes.bool,
	contact: PropTypes.bool,
	sticky: PropTypes.bool
};
