import React, { useState, useCallback, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';

import { toSlug, toTitle } from '../../../../utils/formatters';

const MobileNavLink = ( { disabled, link } ) => {
	const [ expanded, setExpanded ] = useState( false );
	const LinkRef = useRef( null );

	const isActive = useCallback( ( { isCurrent, isPartiallyCurrent } ) => {
		if ( toSlug( link.page ) === '/' && isCurrent || toSlug( link.page ) !== '/' && isPartiallyCurrent ) {
			if ( LinkRef.current ) {
				LinkRef.current.tabIndex = '-1';
			}

			return { className: 'active' };
		}

		if ( LinkRef.current ) {
			LinkRef.current.tabIndex = disabled ? '-1' : '0';
		}

		return null;
	}, [ disabled, link.page ] );

	return (
		<>
			<Link
				activeClassName="active"
				getProps={ isActive }
				ref={ LinkRef }
				to={ toSlug( link.page ) }
				style={{ width: '100%', padding: '.75rem 0' }}
				className="overline"
			>
				{ toTitle( link.page ) }
			</Link>
			{link.associatedPages && (
				<FontAwesomeIcon
					icon={ faCaretDown }
					onClick={ () => setExpanded( !expanded ) }
					style={{
						marginLeft: '4px',
						cursor: 'pointer',
						width: '.75rem'
					}}
				/> )}
			<AnimatePresence exitBeforeEnter>
				{expanded && (
					<motion.ul
						className="mobileNavDropdown"
						initial={{ height: 0 }}
						animate={{ height: 'auto' }}
						exit={{ height: 0 }}
						transition={{ duration: 0.1, ease: 'linear' }}
					>
						{link.associatedPages.map( ( page, i ) => (
							<li key={ i } style={{ padding: 0 }}>
								<Link
									activeClassName="active"
									getProps={ isActive }
									ref={ LinkRef }
									to={ toSlug( page ) }
									style={{ width: '100%', padding: '.75rem 0' }}
								>
									{ toTitle( page ) }
								</Link>
							</li>
						) )}
					</motion.ul>
				)}
			</AnimatePresence>
		</>
	);
};

MobileNavLink.propTypes = {
	disabled: PropTypes.bool,
	link: PropTypes.object.isRequired
};

export default MobileNavLink;
